






































import { debounceProcess } from "@/helpers/debounce";
import { ResponseListWarehouseLocation, ResponseWarehouseLocation } from "@interface/warehouse.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import Vue from "vue";
import { warehouseLocationService } from "@service/warehouse-location.service";
import { IOption } from "@/models/interface-v2/common.interface";
import { DEFAULT_PAGE_SIZE } from "@/models/constant/global.constant";

export default Vue.extend({
  name: "CSelectRack",
  props: {
    value: {
      type: String,
      default: undefined
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    forList: {
      type: Boolean,
      default: true,
    },
    byBranch: {
      type: String,
      default: undefined,
    },
    byWarehouse: {
      type: String,
      default: undefined,
    },
  },
  data() {
    this.searchRack = debounceProcess(this.searchRack, 300);
    this.getList = debounceProcess(this.getList, 300);
    return {
      loading: false,
      dtListRack: {} as ResponseListWarehouseLocation,
      optRack: [] as IOption<ResponseWarehouseLocation>[],
      queryParams: {
        page: 0,
        limit: DEFAULT_PAGE_SIZE,
        search: ""
      }
    };
  },
  watch: {
    value(newValue: string) {
      if (newValue) {
        this.handleMissingOpt();
      }
    },
    byBranch: {
      immediate: true,
      handler: "onChangeBranch"
    },
    byWarehouse: {
      immediate: true,
      handler: "onChangeWarehouse"
    },
  },
  created() {
    if (this.byBranch) {
      this.queryParams.search = "";
    }
    this.getList(this.queryParams);
    this.handleMissingOpt();
  },
  methods: {
    clear(): void {
      this.$emit("input", undefined);
    },
    onChangeWarehouse(newValue: string): void {
      const searchBy: string[] = [];
      if (newValue) {
        searchBy.push(`warehouse.secureId~${newValue}`);
        if (this.byBranch) {
          searchBy.push(`warehouse.branchWarehouse.secureId~${newValue}`);
        }
      }
      this.queryParams.page = 0;
      this.queryParams.search = searchBy.join("_AND_");
      this.optRack = [];
      this.getList(this.queryParams);
    },
    onChangeBranch(newValue: string): void {
      const searchBy: string[] = [];
      if (newValue) {
        searchBy.push(`warehouse.branchWarehouse.secureId~${newValue}`);
        if (this.byWarehouse) {
          searchBy.push(`warehouse.secureId~${newValue}`);
        }
      }
      this.queryParams.page = 0;
      this.queryParams.search = searchBy.join("_AND_");
      this.optRack = [];
      this.getList(this.queryParams);
    },
    getListRack(params: RequestQueryParamsModel): Promise<ResponseListWarehouseLocation> {
      return warehouseLocationService.listWarehouseLocation(params);
    },
    onSelect(val: string, meta: IOption<ResponseWarehouseLocation>): void {
      this.$emit("input", val);
      this.$emit("on-select", { value: val, meta });
    },
    searchRack(search = ""): void {
      if (search) {
        this.queryParams.search = `locationCode~*${search.toUpperCase()}*`;
      }
      if (this.byBranch) {
        this.queryParams.search = `warehouse.branchWarehouse.secureId~${this.byBranch}_AND_name~*${search.toUpperCase()}*_OR_locationCode~*${search.toUpperCase()}*`;
      }
      this.queryParams.page = 0;
      this.optRack = [];
      this.getList(this.queryParams);
    },
    popupScroll(e): void {
      if ((this.dtListRack.totalPages - 1) === this.dtListRack.currentPage) return;
      const target = e.target;
      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        this.queryParams.page += 1;
        this.getList(this.queryParams);
      }
    },
    async getList(params: RequestQueryParamsModel): Promise<void> {
      try {
        this.loading = true;
        const res = await this.getListRack(params);
        const { optRack } = this;
        const opts = res.data.map(x => (
          {
            key: x.locationCode,
            value: x.id,
            meta: x
          }
        ));
        this.optRack = [...optRack, ...opts];
        this.dtListRack = res;
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading = false;
      }
    },
    async handleMissingOpt(): Promise<void> {
      try {
        if (this.forList || !this.value) return;
        const opt = this.optRack.find(x => x.value === this.value);
        if (!opt) {
          const { data } = await this.getListRack({ search: `secureId~${this.value}` });
          const newOpt = { key: data[0].locationCode, value: data[0].id, meta: data[0] };
          const { optRack } = this;
          this.optRack = [...optRack, newOpt];
        }
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    onChange(e): void {
      this.$emit("change", e);
      this.$emit("input", e);
    }
  }
});

